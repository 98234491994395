/* .btn{
    @apply flex justify-center items-center bg-black rounded-full border-none bg-primary-contrast text-white line-height-[2rem] p-5 w-200
}
.btn::before{
    @apply absolute left-[-0.1em] top-[-0.1em] right-[-0.1em] bottom-[-0.1em] -z-10 inset-0 rounded-full backdrop-blur-[10px] bg-gradiant1 from-transparent to-white/100 shadow-button bg-color-secondary
} */

.buttonStyleslight {
    border-radius: 99px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.50) 100%);
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(10px);
}

.buttonStylesDark1{
    border-radius: 99px;
    border-image: linear-gradient(35deg, rgba(255, 255, 255, 0.00) 33.61%, #FFF 89.19%) 1;
    border-color: rgba(255, 255, 255, 0.07);
    border-width: 1px;
    border-style: double;
    background: rgba(0, 0, 0, 0.50);
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(10px);
    /* background-image:  var(--Angular-Gradients-Angular-1, conic-gradient(from 196deg at 50% 50%, rgba(66, 232, 255, 0.00) 95.00793099403381deg, rgba(255, 126, 171, 0.50) 185.5945086479187deg, #3083FF 274.88280057907104deg, #7147FF 360deg)); */
    /* background-origin: border-box;
    /* background-clip: padding-box, border-box; */
    
}





.buttonStylesDark {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
    /* padding: 1rem 2.25rem; */
    border-radius: 1000px;    
    border: none;
    position: relative;
    background: var(--color-primary-contrast);    
    /* font-size: 1rem; */
    line-height: 2rem;
    color: #fff;
}

.buttonStylesDark::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 1000px;
    backdrop-filter: blur(10px);
    top: -0.1em;
    bottom: -0.1em;
    right: -0.1em;
    left: -0.1em;
    z-index: -1;
    background: linear-gradient(35deg, rgba(255, 255, 255, 0.00) 33.61%, #FFF 89.19%);
    background-color: rgba(255, 255, 255, 0.07);    
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.10);
}

/* .buttonStylesDark {
    position: relative;
    display: inline-block;
    padding: 10px 20px; 
    color: white; 
    text-decoration: none; 
}

.buttonStylesDark::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 99px;
    border: 1px double rgba(255, 255, 255, 0.07); 
    border-image: linear-gradient(35deg, rgba(255, 255, 255, 0.00) 33.61%, #FFF 89.19%) 1;
    background: rgba(0, 0, 0, 0.50);
    z-index: -1; 
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(10px);
} */



